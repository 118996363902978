import React, { useState } from 'react';
import '../component/contact.css';
import contactImg from '../assets/images/map.png'; // Update the path according to your image location
import fb from '../assets/images/fb.png';
import insta from '../assets/images/insta.png';
import linkedin from '../assets/images/linkedin.png';
import twitter from '../assets/images/twitter.png';
import youtube from '../assets/images/youtube.png';
import location from '../assets/images/locate.png';
import mail from '../assets/images/mail.png';
import phone from '../assets/images/phone.png';
import boader from '../assets/images/boader.png'

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    address: '',
    interestedIn: '',
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.mobile) newErrors.mobile = 'Mobile number is required';
    if (!formData.address) newErrors.address = 'Address is required';
    if (!formData.interestedIn) newErrors.interestedIn = 'Selection is required';
    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const mailtoLink = `mailto:padmavathibuildings@gmail.com?subject=Contact Form Submission&body=Name: ${formData.name}%0D%0AMobile: ${formData.mobile}%0D%0AAddress: ${formData.address}%0D%0AInterested In: ${formData.interestedIn}`;

    // Clear form data immediately after submit
    setFormData({
      name: '',
      mobile: '',
      address: '',
      interestedIn: '',
    });

    // Redirect to mailto link
    window.location.href = mailtoLink;
  };

  return (
  
    <div className="contact-container" id="contact">
 <div className="project-header row">
      {/* <div className="getin text-center"> */}
        <h2 className="sticky">Get In Touch<br></br>
        <img src={boader} alt='boader'className='boader'/></h2>
      {/* </div> */} 
      <div className="contact-form-container">
        <div className="image-container">
          <img src={contactImg} alt="Contact Map" className="contact-img" />
        </div>
        <form className="contact-form text-start" onSubmit={handleSubmit}>
          <label htmlFor="Name" className='label-over'><span>Name</span></label>
          <input
            type="text"
            name="name"
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleChange}
            maxLength={25}
            required
          />
          {errors.name && <p className="error">{errors.name}</p>}
          
          <label htmlFor="Mobile" className="label-over"><span>Mobile</span></label>
          <input
            type="text"
            name="mobile"
            placeholder="Enter your mobile"
            value={formData.mobile}
            onChange={handleChange}
            maxLength={12}
            minLength={10}
            required
          />
          {errors.mobile && <p className="error">{errors.mobile}</p>}

          <label htmlFor="Address" className='label-over'><span>Address</span></label>
          <input
            type="text"
            name="address"
            placeholder="Enter your address"
            value={formData.address}
            onChange={handleChange}
            maxLength={40}
            required
          />
          {errors.address && <p className="error">{errors.address}</p>}

          <label htmlFor="intersted" className='label-over'><span>Interested In</span></label>
          <select
            name="interestedIn"
            value={formData.interestedIn}
            onChange={handleChange}
            required 
          >
            <option value="" disabled>
              Select
            </option>
            <option value="option1">1 Bhk</option>
            <option value="option2">2 Bhk</option>
            <option value="option3">3 Bhk</option>
          </select>
          {errors.interestedIn && <p className="error">{errors.interestedIn}</p>}

          <button type="submit">
            <strong>Submit</strong>
          </button>
        </form>
      </div>
      <div className="contact-details address">
  <div className="addresses">
    <h3>Address</h3>
    <p style={{lineHeight:"0.5em"}}>
      <a 
        href="https://www.google.com/maps/place/Aryavarta+One/@17.2242459,78.4726847,17z/data=!3m1!4b1!4m6!3m5!1s0x3bcbbb83eb82167b:0x19507249f76b38fa!8m2!3d17.2242459!4d78.4752596!16s%2Fg%2F11rk5vf4wb?entry=ttu&g_ep=EgoyMDI0MDkxMS4wIKXMDSoASAFQAw%3D%3D" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <img src={location} alt='img' className='navigate' />
        &nbsp;  Beside Axis Bank, 

        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;RoadNo 1 Banjarahills,
        <br /><br/><br/> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;Telangana 500034 .
      </a>
    </p>
  </div>
  <div className="contact">
    <h3>Contact</h3>
    <p style={{lineHeight:"0.5em",marginRight:"25px"}}>
      <a href="tel:+919666566640 ">
        <img src={phone} alt='img' className='navigation' />
        +91 9666566640 
       <br></br>
        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; +91 9999998888 */}
      </a>
    </p>
  </div>
  <div>
    <div className="email">
      <h3>Email</h3>
      <p>
        <a href="mailto:padmavathibuildings@gmail.com">
          <img src={mail} alt='img' className='navigations' />
          &nbsp;&nbsp;padmavathibuildings@gmail.com
        </a>
      </p>
    </div>
    {/* <div className='social-media'style={{marginTop:"-15px"}}>
      <a href="https://www.facebook.com">
      <img src={fb} className="social" alt='Facebook'/>
      </a>
      <a href="">
      <img src={youtube} className="social" alt='you tube' />
      </a>
      <a href="https://www.linkedin.com">
      <img src={linkedin} className="social" alt='linkedin'/>
      </a>
      <a href="https://www.instagram.com/aryavarta.lifespaces/" target='_blank'>
      <img src={insta} className="social" alt='instagram'/>
      </a>
      <a href="https://www.twitter.com">
      <img src={twitter} className="social" alt='twitter'/>
      </a>
    </div> */}
  </div>
  </div>
</div>

    </div>
   
  );
}

export default Contact;


